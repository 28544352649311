import { Card, Grid, Typography, Box, Tabs, Tab } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Nav } from '../components/Header/Nav'
import { Link, useLocation } from 'react-router-dom'
import '../App.css'

export const Orders = () => {
    const location = useLocation()
    const [pOrders,setPOrders] = useState([])
    const [aOrders,setAOrders] = useState([])
    const [cOrders,setCOrders] = useState([])
    const user_id = localStorage.getItem('userId')
    const [value, setValue] = React.useState(location.state?.tab || 0);

    useEffect(() => {
        const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        API.get(
            "api/dealer_order/"+user_id+"/Pending",
            {}
        ).then((response) => {
            const {status, data} = response;
            if(status === 200) {
                setPOrders(data)
            }
        }).catch((error) => {
            alert(error)
        });
    },[user_id])

    useEffect(() => {
        const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        API.get(
            "api/dealer_order/"+user_id+"/Accepted",
            {}
        ).then((response) => {
            const {status, data} = response;
            if(status === 200) {
                setAOrders(data)
            }
        }).catch((error) => {
            alert(error)
        });
    },[user_id])

    useEffect(() => {
        const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        API.get(
            "api/dealer_order/"+user_id+"/Confirmed",
            {}
        ).then((response) => {
            const {status, data} = response;
            if(status === 200) {
                setCOrders(data)
            }
        }).catch((error) => {
            alert(error)
        });
    },[user_id])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{marginLeft: '-40px'}}
          >
            {value === index && (
              <Box>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }
      
    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const convertDate = (date) => {
        const dateObject = new Date(date)
        return dateObject.toLocaleDateString();
    }

    const convertTime = (date) => {
        const dateObject = new Date(date)
        return dateObject.toLocaleTimeString();
    }

    const OrdersList = (orders) => {
        if(orders.length === 0){
            return(
                <Grid p={4} m={2}>
                    <Typography variant='h5'>
                        No Orders
                    </Typography>
                </Grid>
            )
        }else{
            return(
                orders.map((order) => 
                    ['Pending','Accepted'].includes(order.status) ?
                    <Link to={{pathname: order.status === 'Pending' ? "/orderDetail" : "/confirmOrder", state: {orderId: order.id, status: order.status, leadId: order.lead_id, code: order.lead__project_code}}} key={order.id} className='home-link ordersContainer'>
                        <Card style={{ background: "#FFFCF9 0% 0% no-repeat padding-box", boxShadow: '1px 1px 6px #99999933', margin: '20px 0', width: '100%' }} className={order.status==="Pending" ?'pendingOrder':'acceptedOrder'}>
                            <Grid container className='flex-space'>
                                <Typography variant='h6' color={"#333333"}>
                                    Order ID: {order.id}
                                </Typography>
                                <Typography variant='h6' style={{textDecoration: 'underline'}} >
                                    {order.status}
                                </Typography>
                            </Grid>
                            <Grid container className='flex-space' color={"#777777"}>
                                <Typography variant='body1'>
                                    {order.order_code}
                                </Typography>
                                <Typography variant='body1'>
                                    {convertDate(order.created_at)}
                                </Typography>
                                <Typography variant='body1'>
                                    {convertTime(order.created_at)}
                                </Typography>
                            </Grid>
                        </Card>
                    </Link>
                    :
                    <Card style={{ background: "#FFFCF9 0% 0% no-repeat padding-box", boxShadow: '1px 1px 6px #99999933', margin: '20px 0', width: '100%' }} className={order.status==="Pending" ?'pendingOrder':'acceptedOrder'}>
                        <Grid container className='flex-space'>
                            <Typography variant='h6' color={"#333333"}>
                                Order ID: {order.id}
                            </Typography>
                            <Typography variant='h6' style={{textDecoration: 'underline'}} >
                                {order.status}
                            </Typography>
                        </Grid>
                        <Grid container className='flex-space' color={"#777777"}>
                            <Typography variant='body1'>
                                {order.order_code}
                            </Typography>
                            <Typography variant='body1'>
                                {convertDate(order.created_at)}
                            </Typography>
                            <Typography variant='body1'>
                                {convertTime(order.created_at)}
                            </Typography>
                        </Grid>
                    </Card>
                    
                )
            );
        }
    }

    return (
        <><Nav /><Grid container className="flex-center" p={3} mb={6} sx={{flexDirection:"column"}}>
            <Typography>
                Orders
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Pending" {...a11yProps(0)} />
                    <Tab label="Accepted" {...a11yProps(1)} />
                    <Tab label="Confirmed" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} md={6}>
                {OrdersList(pOrders)}
            </TabPanel>
            <TabPanel value={value} index={1} md={6}>
                {OrdersList(aOrders)}
            </TabPanel>
            <TabPanel value={value} index={2} md={6}>
                {OrdersList(cOrders)}
            </TabPanel>
        </Grid></>
    )
}
