import { Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Nav } from '../components/Header/Nav';
import UploadIcon from '@mui/icons-material/Upload';
import axios from 'axios';
import { Buttons } from '../components/Button/buttons';

export const BillUpload = () => {
    const location = useLocation();
    const user_id = localStorage.getItem('userId')
    const order_id = location.state.orderId;
    const [billDate, setBillDate] = useState(location.state?.billDate || '');
    const [billNo, setBillNo] = useState(location.state?.billNo || '');
    const [billAmount, setBillAmount] = useState(location.state?.billAmount || '');
    const [file,setFile] = useState('')
    const billFile = location.state?.file;
    const history = useHistory()

    const handleFileUpload = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    }

    const handleBillUpload = () => {
        if(billDate === ''){
            alert('Please Select Date');
            return;
        }
        if(billNo === ''){
            alert('Please enter Bill No');
            return;
        }
        if(billAmount === ''){
            alert('Please enter Bill Amount');
            return;
        }
        if(!billFile && file === ''){
            alert('Please Upload Bill');
            return;
        }
        const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        const formData = new FormData();
        formData.append('order_id', order_id)
        formData.append('bill_date', billDate)
        formData.append('bill_no', billNo)
        formData.append('bill_amount', billAmount)
        if (file !== '') {
            formData.append('file', file)  
        } 
        API.post(
            `api/dealer_bill_upload/${user_id}/`,
            formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
        }).then((response) => {
            const {status} = response;
            if(status === 200) {
                history.push("/bills")
            }
        }).catch((error) => {
            alert(error)
        });
    }

    return(
        <><Nav />
        <Grid container className="flex-center" p={3} mb={6}>
            <Grid item xs={12}>
                <Typography variant='h5' color={"#396773"}>
                    Order ID: {order_id}
                </Typography>
            </Grid>
            <Grid item xs={12} my={3}>
                <Typography variant='body1' color={"#396773"}>
                    Bill Details
                </Typography>
            </Grid>
            <Grid container my={3}>
                <Grid item xs={6}>
                    <Typography>Bill Date</Typography>
                    <Typography color={"#999999"}>as mentioned in the bill</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField variant='outlined' required type="date" value={billDate} InputLabelProps={{shrink: true,}} onChange={(e) => setBillDate(e.target.value)} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography>Bill No.</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField variant='outlined' size='small' required value={billNo} onChange={(e) => setBillNo(e.target.value)} />
                </Grid>
            </Grid>
            <Grid container my={3}>
                <Grid item xs={6}>
                    <Typography>Enter Bill Amount</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField variant='outlined' size='small' type="number" required value={billAmount} onChange={(e) => setBillAmount(e.target.value)} />
                </Grid>
            </Grid>
            <Grid container className='flex-center text-center' mt={2} >
                <Grid item xs={6} >
                    <Typography variant='h5' color={"#396773"} mb={2}>Upload Bill</Typography>
                    <div className="file-card">
                        <input type="file" id="file-input" className="file-input" onChange={handleFileUpload} />
                        <label htmlFor="file-input" className="card-label">
                            <Grid container className="flex flex-center">
                                <Grid item xs={12} className='text-center' my={3}>
                                    <UploadIcon />
                                </Grid>
                                <span className="card-text">Choose a File</span>
                            </Grid>
                        </label>
                    </div>
                    <Typography>{file ? file.name : ''}</Typography>
                </Grid>
            </Grid>
            <Grid container mt={3}>
                <Grid item xs={12}>
                    <Buttons variant='contained' fullWidth color="accept" onClick={handleBillUpload}>Update</Buttons>
                </Grid>
            </Grid>
        </Grid>
        </>
    );
}