import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { PageNotFound } from './404'
import { Orders } from './Orders'
import { Home } from './Home'
import { Login } from './Login'
import { OrderDetail } from './OrderDetail'
import { ConfirmOrder } from './ConfirmOrder'
import { Profile } from './Profile'
import { BillUpload } from './BillUpload'
import { Bills } from './Bills'
import { ViewBill } from './ViewBill'

export const router = (props) => {
    
    return (
        <Switch>
            <Route path='/orders' render={(props) => <Orders {...props}/>} />
            <Route path='/orderDetail' render={(props) => <OrderDetail {...props}/>} />
            <Route path="/confirmOrder" render={(props) => <ConfirmOrder {...props}/>} />
            <Route path="/profile" render={(props) => <Profile {...props}/>} />
            <Route path="/bills" render={(props) => <Bills {...props}/>} />
            <Route path="/viewBill" render={(props) => <ViewBill {...props}/>} />
            <Route path="/uploadBill" render={(props) => <BillUpload {...props}/>} />
            <Route path='/home' render={(props) => <Home {...props}/>} />
            <Route path="/" render={(props) => <Login {...props}/> } />
            <Route component={PageNotFound}/>
        </Switch>
    )
}
