import React, { useEffect, useState } from 'react'
import { Nav } from '../components/Header/Nav'
import { Divider, Grid, TableContainer, Typography, Paper, TableHead, TableRow, TableCell, Table, TableBody, CircularProgress, TextField, Autocomplete} from '@mui/material';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { Buttons } from '../components/Button/buttons';
import FormControl from '@mui/material/FormControl';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Add } from '@mui/icons-material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ConfirmOrder = (props) => {
    const location = useLocation();
    const user_id = localStorage.getItem('userId')
    const order_id = location.state.orderId;
    const lead_id = location.state.leadId;
    const line_items = location.state.items;
    const [order,setOrder] = useState([]);
    const [comments, setComments] = useState('');
    const [productId, setproductId] = useState('');
    const [productCode, setproductCode] = useState('');
    const [productQty, setproductQty] = useState('');
    const [preview,setPreview] = useState(false);
    const [addOrder, setaddOrder] = useState(false);
    const [productList, setproductList] = useState([]);
    const [skuList, setSkuList] = useState([]);
    const [skuId, setSkuId] = useState('');
    const [open,setOpen]=useState(false)

    useEffect(() => {
        let list_items = []
        if(!line_items){
            const API = axios.create({
                baseURL: process.env.REACT_APP_API_URL
            })
            API.post(
                `api/dealer_order/${user_id}/`,
                {
                    "order_id": order_id,
                    "lead_id": lead_id
                }
            ).then((response) => {
                const {status, data} = response;
                if(status === 200) {
                    list_items = data.material_rows
                    const final_list = list_items.map((item) => {
                        return { ...item, available_qty: item.quantity };
                    });
                    setOrder(final_list)
                }
            }).catch((error) => {
                alert(error)
            });
        }else{
            const final_list = line_items.map((item) => {
                return { ...item, available_qty: item.quantity };
            });
            setOrder(final_list)
        }
    },[lead_id, line_items, order_id, user_id])

    const updateQuantity = (event) => {
        const key = parseInt(event.target.getAttribute('inputId'));
        const updatedArray = order.map((item) => {
            if (item.id === key) {
                return { ...item, available_qty: event.target.value };
            }
            return item;
        });
        setOrder(updatedArray)
    }

    const updateComments = (event) => {
        setComments(event.target.value);
    }
    const updateproductId = (event, newValue) => {
        setproductId(newValue?.id);
    }
    const updateproductCode = (event) => {
        setproductCode(event.target.value);
    }
    const updateproductQty = (event) => {
        setproductQty(event.target.value);
    }
    const updateSkuID = (event, newValue) => {
        setSkuId(newValue?.id);
    }
    const confirmOrder = async() => {
        const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        API.post(
            `api/dealer_order_preview/${order_id}/`,
            {
                "material_detail": order,
                "comments": comments
            }
        ).then((response) => {
            const {status} = response;
            if(status === 200) {
                showPreview()
            }
        }).catch((error) => {
            alert(error)
        });
    }
    const handleAddOrder = async () => {
        confirmOrder();
        setaddOrder(false);
         const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        API.post(
            `api/dealer_order_preview/${order_id}/`,
            {
                 "material_detail": [
                    {
                    "id": null,
                    "product_id": productId,
                    "code": productCode,
                    "sku_id": skuId,
                    "available_qty": productQty
                    }
                ],
                "comments":comments
            }
        ).then((response) => {
            const {status} = response;
            if(status === 200) {
                setOpen(true)
            }
        }).catch((error) => {
            alert(error)
        });

    }
    const showPreview = async() => {
        setPreview(true)
        setOrder([])
        const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        API.get(
            `api/dealer_order_preview/${order_id}/`,
        ).then((response) => {
            const {status, data} = response;
            if(status === 200) {
                setOrder(data.materail_list)
            }
        }).catch((error) => {
            alert(error)
        });
    }

    const enableEdit = () => {
        setPreview(false)
    }

    useEffect(() => {
        const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        API.get(
            "api/get_product_names",
            {}
        ).then((response) => {
            const { status, data } = response;
            if (status === 200) {
                setproductList(data.products);
                setSkuList(data.skus);
            }
        }).catch((error) => {
            alert(error)
        });
    }, []);

   
    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

    return(
        < ><Nav /><Grid container className='flex-column' p={3} mb={5}>
            <Typography xs={12} variant="h6" color={"#396773"}>Order Details : {order_id}</Typography>
            <Grid container my={2} className='flex-start'>
                <Typography variant="body1" color={"#555555"}>Order Availablity</Typography>
            </Grid>
            <Divider />
            {order ?
            <><TableContainer component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ backgroundColor: '#FFF8F2', color: "#333333" }}>Product Name</TableCell>
                                <TableCell style={{ backgroundColor: '#FFF8F2', color: "#333333" }}>Code</TableCell>
                                {preview ?
                                <TableCell style={{ backgroundColor: '#FFF8F2', color: "#333333" }}>Available/Ordered</TableCell>
                                :
                                <><TableCell style={{ backgroundColor: '#FFF8F2', color: "#333333" }}>Qty. Available</TableCell><TableCell style={{ backgroundColor: '#FFF8F2', color: "#333333" }}>Qty. Ordered</TableCell></>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {order.map((row) => <TableRow>
                                <TableCell>{row.material__material_name}</TableCell>
                                <TableCell>{row.code}</TableCell>
                                {preview ?
                                <TableCell>{row.available_qty}/{row.quantity} ({row.sku_unit})</TableCell>
                                :
                                <><TableCell><TextField type='number' inputProps={{ 'inputid': row.id }} value={row.available_qty} onChange={updateQuantity} /></TableCell><TableCell>{row.quantity} x {row.sku_unit}</TableCell></>
                                }
                            </TableRow>
                            )}
                            {addOrder === true &&
                                <TableRow>
                                    <TableCell>
                                        <FormControl variant="standard" fullWidth>
                                        <Autocomplete
                                            options={productList}
                                            getOptionLabel={(option) => option.material_name}
                                            onChange={updateproductId}
                                            autoComplete
                                            renderInput={(params) => (
                                                <TextField {...params} label="Product Name" variant="outlined" />
                                            )}
                                        />
                                        </FormControl>
                            </TableCell>
                            <TableCell><TextField value={productCode} onChange={updateproductCode} id="standard-basic" label="Code" variant="standard" /></TableCell>
                            <TableCell>
                                <FormControl variant='standard' fullWidth>
                                    <Autocomplete
                                        options={skuList}
                                        getOptionLabel={(option) => option.sku_unit}
                                        onChange={updateSkuID}
                                        autoComplete
                                        renderInput={(params) => (
                                            <TextField {...params} label="SKU Type" variant="outlined" />
                                        )}
                                    />
                                </FormControl>
                            </TableCell>
                            <TableCell><TextField value={productQty} onChange={updateproductQty} id="standard-basic" label="Qty. Ordered" variant="standard" type="number"/></TableCell>
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                    {addOrder === true ?
                    <button onClick={handleAddOrder} className='submitNewOrder'>Submit</button>
                     :   preview===false&&
                    <button onClick={()=>setaddOrder(true)} className='addNewOrder'>Add order<Add fontSize='small' /></button>
                        
                    }
                    <Grid container className='flex-center' p={2}>
                        {
                            preview ? 
                            <Buttons color='accept' variant='outlined' onClick={enableEdit} size='large'>Edit Order</Buttons>
                            :
                            <TextField value={comments} onChange={updateComments} placeholder="Comments..." />
                        }
                    </Grid>
                </TableContainer>
                {preview ? 
                    <Buttons fullWidth variant='outlined' color='accept'>
                        <Link to={{pathname: "/uploadBill", state: {orderId: order_id}}} className='home-link'>
                            Upload Bill
                        </Link>
                    </Buttons>
                    :
                    <Buttons fullWidth variant="outlined" color='accept' onClick={confirmOrder} size='large'>CONFIRM</Buttons>
                }
                </>
            :
            <CircularProgress color='success' />
            }
        </Grid>
             <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Order added successfully!
        </Alert>
      </Snackbar>
        </>
    )
}