import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import { Link,  useHistory, useLocation } from 'react-router-dom'
import { AppBar, Drawer, IconButton, Toolbar, Grid, Typography, Divider, Paper, List, ListItem, ListItemText, ListItemButton } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BottomNav } from './BottomNav';

export const Nav = (props) => {
    
    const history = useHistory()
    const location = useLocation()
    const pathname = location.pathname
    const [isPathname, setPathname] = useState(pathname)
    const [open, setOpen] = useState(false)

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }));
    
    useEffect(() => {
        setPathname(pathname)
    }, [pathname])

    const handleMenuOpen = async() => {
        setOpen(!open);
    }
    
    const logOut = () => {
        localStorage.removeItem('userId')
        history.push('/')
    }
    
    return (
        <>
            <AppBar position='static' color='default'>
                <Toolbar className='flex-space'>
                    {['/home','/bills','/orders'].includes(isPathname) ? <IconButton color='inherit' size='large' onClick={handleMenuOpen}><MenuIcon /></IconButton> : <IconButton onClick={() => history.goBack()}><ArrowBackIcon /></IconButton>}
                    <Typography>AKP Dealer App</Typography>
                    <IconButton
                        size='large'
                        color='inherit'
                    >
                        <NotificationsIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer variant='persistent' anchor='left' open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleMenuOpen}>
                        <ArrowBackIcon />
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <Grid container>
                    <List>
                        <ListItem>
                            <Link to="/profile" className='home-link'>
                                <ListItemButton>
                                    <ListItemText primary="Profile" />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <ListItem>
                            <ListItemButton onClick={logOut}>
                                <ListItemText primary="Logout" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Grid>
            </Drawer>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} style={{zIndex: 999}} elevation={3}>
                <BottomNav {...props}/>
            </Paper>
        </>
    )
}
