import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import React from 'react'
import HomeIcon from '@mui/icons-material/Home';
import InventoryIcon from '@mui/icons-material/Inventory';
import { Link, useLocation } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';

export const BottomNav = (props) => {
    const location = useLocation();

    return(
        <BottomNavigation
            showLabels
            value={location.pathname}
        >
            <BottomNavigationAction component={Link} to="/home" value="/home" label="Home" icon={<HomeIcon />} />
            <BottomNavigationAction component={Link} to="/orders" value="/orders" label="Orders" icon={<InventoryIcon />} />
            <BottomNavigationAction component={Link} to="/bills" value="/bills" label="Bills" icon={<ReceiptIcon />} />
        </BottomNavigation>
    );
}