import { Divider, Grid, TableContainer, Typography, Paper, TableHead, TableRow, TableCell, Table, TableBody, CircularProgress, Button, createTheme, ThemeProvider, Dialog, FormGroup, FormControlLabel, Checkbox, Input } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { Nav } from '../components/Header/Nav';
import PlaceIcon from '@mui/icons-material/Place';

export const OrderDetail = (props) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const user_id = localStorage.getItem('userId')
    const order_id = params.get('order_id') || location.state.orderId;
    const order_status = location.state?.status || "Pending";
    const project_code = location.state?.code;
    const lead_id = params.get('lead_id') || location.state?.leadId;
    const [order,setOrder] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [showComments, setShowComments] = useState(false);
    const [comments, setComments] = useState('');
    const history = useHistory();

    if(!user_id){
        history.push("/");
    }

    const theme = createTheme({
        palette: {
          deny: {
            main: '#FFF8F2',
            contrastText: '#396773',
          },
          accept: {
            main: '#396773',
            contrastText: '#fff',
          },
        },
    });

    useEffect(() => {
        const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        API.post(
            `api/dealer_order/${user_id}/`,
            {
                "order_id": order_id,
                "lead_id": lead_id
            }
        ).then((response) => {
            const {status, data} = response;
            if(status === 200) {
                setOrder(data)
            }
        }).catch((error) => {
            alert(error)
        });
    },[lead_id, order_id, user_id])

    const rejectOrder = () => {
        setOpenDialog(true);
    }

    const handleClose = () => {
        setOpenDialog(false);
    }

    const submitReject = (event) => {
        event.preventDefault();
        const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        API.put(
            `api/dealer_order/${user_id}/`,
            {
                "order_id": order_id,
                "status": "Rejected",
                "reject_reason": selectedOption === 'Others' ? comments : selectedOption
            }
        ).then((response) => {
            const {status} = response;
            if(status === 200) {   
                history.push("/orders");
            }
        }).catch((error) => {
            alert(error)
        });
        setSelectedOption('');
        setComments('');
        setShowComments(false);
    }

    const acceptOrder = () => {
        const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        API.put(
            `api/dealer_order/${user_id}/`,
            {
                "order_id": order_id,
                "status": "Accepted",
            }
        ).then((response) => {
            const {status} = response;
            if(status === 200) {
                if (order.ppm){
                    history.push({
                        pathname: "/confirmOrder",
                        state: {
                            items: order.material_rows,
                            orderId: order_id, status: order_status, leadId: lead_id
                        }
                    });
                }else{
                    history.push({
                        pathname: "/uploadBill", state: {orderId: order_id}
                    });
                }
            }
        }).catch((error) => {
            alert(error)
        });
    }

    const handleCommentsChange = (event) => {
        setComments(event.target.value);
    };

    const handleCheckboxChange = (event) => {
        const value =  event.target.value
        console.log(value);
        setSelectedOption(value);
        setShowComments(value === 'Others')
    }

    return(
        <><Nav /><Grid container className='flex-column' p={3} mb={6}>
            <Typography xs={12} variant="h6" color={"#396773"}>Order Details : {order_id} ({project_code || order.project_code})</Typography>
            <Grid container my={2} className='flex-start'>
                <PlaceIcon mr={2} />
                <Typography variant="body1" color={"#555555"}>{order.address}</Typography>
            </Grid>
            <Divider />
            {order ?
                order.material_rows ?
                <TableContainer component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{backgroundColor: '#FFF8F2', color: "#333333"}}>Product Name</TableCell>
                                <TableCell style={{backgroundColor: '#FFF8F2', color: "#333333"}}>Code</TableCell>
                                <TableCell style={{backgroundColor: '#FFF8F2', color: "#333333"}}>SKU Type</TableCell>
                                <TableCell style={{backgroundColor: '#FFF8F2', color: "#333333"}}>Quantity</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {order.material_rows?.map((row) => 
                                <TableRow key={row.material__material_name}>
                                    <TableCell>{row.material__material_name}</TableCell>
                                    <TableCell>{row.code}</TableCell>
                                    <TableCell>{row.sku_unit}</TableCell>
                                    <TableCell>{row.quantity}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <img alt="bill" src={order.file_url} style={{width: "100%"}} />
            :
            <CircularProgress color='success' />
            }
            {
                order_status === 'Pending' &&
                <><ThemeProvider theme={theme}>
                    <Grid container mt={3}>
                        <Grid item xs={6}>
                            <Button fullWidth variant='contained' size='large' color='deny' onClick={rejectOrder}>DENY</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth variant='contained' size='large' color='accept' onClick={acceptOrder}>ACCEPT</Button>
                        </Grid>
                    </Grid>
                </ThemeProvider>
                <Dialog onClose={handleClose} open={openDialog}>
                    <Grid m={4} p={3}>
                        <FormGroup>
                            {order.reject_reason?.map((row) => 
                                <FormControlLabel control={<Checkbox checked={selectedOption === row} onChange={handleCheckboxChange} value={row} />} label={row} key={row} />
                            )}
                            {showComments && 
                            <Input
                                type="text"
                                value={comments}
                                onChange={handleCommentsChange}
                                placeholder="Enter comments"
                                fullWidth
                                required
                            />}
                            <Button type='submit' onClick={submitReject} disabled={selectedOption===''} variant='contained'>Submit</Button>
                        </FormGroup>
                    </Grid>
                </Dialog></>
            }
        </Grid></>
    );
}