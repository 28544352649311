import { Button, createTheme, ThemeProvider } from '@mui/material';
import React from 'react';

export const Buttons = (props) => {

    const theme = createTheme({
        palette: {
          deny: {
            main: '#FFF8F2',
            contrastText: '#396773',
          },
          accept: {
            main: '#396773',
            contrastText: '#fff',
          },
        },
    });

    return(
        <ThemeProvider theme={theme}>
            <Button fullWidth variant='contained' size='large' color={props.color} onClick={props.onClick}>{props.children}</Button>
        </ThemeProvider>
    )
}