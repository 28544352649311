import { Card, Grid, Typography, Box, Tabs, Tab, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Nav } from '../components/Header/Nav'
import { Link, useLocation } from 'react-router-dom'
import '../App.css'
import ReceiptIcon from '@mui/icons-material/Receipt';

export const Bills = () => {
    const location = useLocation()
    const [bills,setBills] = useState([])
    const user_id = localStorage.getItem('userId')
    const [value, setValue] = React.useState(location.state?.tab || 0);
    const [groupData,setGroup] = useState({})

    useEffect(() => {
        const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        API.post(
            `api/dealer_billlisting/${user_id}/`,
            {
                "status": "all"
            }
        ).then((response) => {
            const {status, data} = response;
            if(status === 200) {
                setBills(data.bill_list)
            }
        }).catch((error) => {
            alert(error)
        });
    },[user_id])

    useEffect(() => {
        const groupedData = bills.reduce((groups, item) => {
            const { bill_status } = item;
            if (!groups[bill_status]) {
              groups[bill_status] = [];
            }
            groups[bill_status].push(item);
            return groups;
        }, {});
        setGroup(groupedData)
    },[bills])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{marginLeft: '-40px'}}
          >
            {value === index && (
              <Box>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }
      
    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const StatusClass = (status) => {
        var clasStatus = ''
        switch(status){
            case 'Verified':
                clasStatus = 'verifiedBill'
                break;
            case 'Processed':
                clasStatus = 'processedBill'
                break;
            case 'Not Verified':
                clasStatus = 'notVerifiedBill'
                break;
            case 'Rejected':
                clasStatus = 'rejectedBill'
                break;
            default:
                clasStatus = 'pendingBill'
                break;
        }
        return clasStatus
    }

    const CardActionCustom = (bill) => {
        const billObject = bill.bill
        var text = ''
        var action = ''
        switch(billObject.bill_status){
            case 'Pending':
                text = "Upload Bill"
                action = {pathname: "/uploadBill", state: {orderId: billObject.id}}
                break;
            case 'Not Verified':
                text = 'Edit Bill'
                action = {pathname: "/uploadBill", state: {orderId: billObject.id, billDate: billObject.dealermaterialbills__bill_date, billNo: billObject.dealermaterialbills__bill_no, billAmount: billObject.dealermaterialbills__bill_amount, file: billObject.dealermaterialbills__bill_image}}
                break;
            case 'Rejected':
                text = 'Re-Upload Bill'
                action = {pathname: "/uploadBill", state: {orderId: billObject.id}}
                break;
            default:
                text = "View Bill"
                action = {pathname: "/viewBill", state: {bill: billObject.dealermaterialbills__bill_image}}
                break;
        }

        return(
            <Card className='text-center' style={{width: '100%', padding: '10px 20px', background: "#FEF0E6 0% 0% no-repeat padding-box"}}>
                <Link to={action} className='home-link'>
                    <Grid container className='flex-center' style={{color: "#396773"}}>
                        <ReceiptIcon />
                        <Typography>{text}</Typography>
                    </Grid>
                </Link>
            </Card>
        )
    }

    const BillsList = (bills) => {
        if(bills.length === 0){
            return(
                <Grid p={4} m={2}>
                    <Typography variant='h5'>
                        No Bills
                    </Typography>
                </Grid>
            )
        }else{
        return(
            bills.map((bill,index) => 
                <Grid item xs={12} key={bill.id+index} className='cardContainer'>
                    <Card style={{ background: "#FFFCF9 0% 0% no-repeat padding-box", boxShadow: '1px 1px 6px #99999933', marginTop: '20px', width: '100%', padding: '20px', paddingTop: '40px'}} className={StatusClass(bill.bill_status)}>
                        <Grid container className='flex-space'>
                            <Grid item>
                                <Typography>Bill No. {bill.dealermaterialbills__bill_no || "NA"}</Typography>
                                <Typography>Bill Date. {bill.dealermaterialbills__bill_date || "NA"}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>Order Amount</Typography>
                                <Typography>{bill.dealermaterialbills__bill_amount || "NA"}</Typography>
                            </Grid>
                        </Grid>
                    </Card>
                    <CardActionCustom bill={bill} />
                </Grid>
            )
        );
        }
    }

    return (
        <><Nav /><Grid container className="flex-center" p={2} mb={6} sx={{flexDirection:"column"}}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="All Bills" {...a11yProps(0)} />
                    {Object.keys(groupData).map((category, index) => <Tab label={category} {...a11yProps(index)} />)}
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} >
                {bills ? BillsList(bills) : <CircularProgress color='success' />}
            </TabPanel>
            {Object.keys(groupData).map((category,index) => 
                <TabPanel value={value} index={index+1}>
                    {groupData[category] ? BillsList(groupData[category]) : <CircularProgress color='success' />}
                </TabPanel>
            )}
        </Grid></>
    )
}
