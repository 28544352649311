import React, {useEffect, useState} from 'react'
import { StatsCard } from '../components/Card/Card'
import { Grid } from '@mui/material'
import BoltIcon from '@mui/icons-material/Bolt';
import { Link, useHistory } from 'react-router-dom';
import { Nav } from '../components/Header/Nav';
import axios from 'axios';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export const Home = (props) => {
    const user_id = localStorage.getItem("userId");
    const history = useHistory()
    const [details, setDetails] = useState({})

    useEffect(() => {
        if (!user_id) {
            history.push('/');
        }
    },[history, user_id])

    useEffect(() => {
        const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        API.get(
            `api/dealer_dashboard/${user_id}`,
            {}
        ).then((response) => {
            const {status, data} = response;
            if(status === 200) {
                setDetails(data)
            }
        }).catch((error) => {
            alert(error)
        });
    },[user_id])

    return (
        <><Nav /><Grid container className='flex-center'>
            <Grid item xs={12} m={3} md={6}>
                <Link to={{pathname: "/orders", state: {tab: 0}}} className='home-link'>
                    <StatsCard
                        style={{ background: 'transparent linear-gradient(180deg, #346D79 0%, #0D5966 50%, #01414B 100%) 0% 0% no-repeat padding-box', color: '#fff' }}
                        text="New Orders"
                        value={details.pending_order}
                        icon={<BoltIcon />} />
                </Link>
                <Link to={{pathname: "/orders", state: {tab: 1}}} className='home-link'>
                    <StatsCard
                        style={{border: '1px solid #F89673',color: '#F89673'}}
                        text="Accepted Orders"
                        value={details.accepted_order}
                        icon={<ThumbUpIcon />} />
                </Link>
                <Link to={{pathname: "/orders", state: {tab: 2}}} className='home-link'>
                    <StatsCard
                        style={{background: 'transparent linear-gradient(180deg, #346D79 0%, #0D5966 50%, #01414B 100%) 0% 0% no-repeat padding-box', color: '#fff'}}
                        text="Confirmed Orders"
                        value={details.confirmed_order}
                        icon={<CheckCircleOutlineIcon />} />
                </Link>
                <Link to={{pathname: "/bills", state: {tab: 0}}} className='home-link'>
                    <StatsCard
                        style={{ border: '1px solid #F89673',color: '#F89673'}}
                        text="Pending Bills"
                        value={details.pending_bills}
                        icon={<ReceiptIcon />} />
                </Link>
            </Grid>
        </Grid></>
    )
}
