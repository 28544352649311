import { Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Nav } from '../components/Header/Nav'
import EditNoteIcon from '@mui/icons-material/EditNote';
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import axios from 'axios';

export const Profile = (props) => {
    const user_id = localStorage.getItem('userId')
    const [profile, setProfile] = useState({})
    const [editProfile,setEditProfile] = useState(false)
    const [bank, setBank] = useState({})
    const [editBank,setEditBank] = useState(false)
    const [editTax, setEditTax] = useState(false)

    useEffect(() => {
        const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        API.get(
            `api/dealer_profile/${user_id}/`,
            {}
        ).then((response) => {
            const {status, data} = response;
            if(status === 200) {
                setProfile(data.users)
                setBank(data.bank_details[0])
            }
        }).catch((error) => {
            alert(error)
        });
    },[user_id])

    const handleChangeProfile = (event) => {
        const value = event.target.value;
        const type = event.target.getAttribute('inputtype')
        var newProfile = {...profile};
        newProfile[type] = value;
        setProfile(newProfile)
    }

    const saveProfile = () => {
        const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        var newProfile = {...profile};
        newProfile["update_category"] = "profile";
        API.post(
            `api/dealer_profile/${user_id}/`,
            newProfile
        ).then((response) => {
            const {status} = response;
            if(status === 200) {
                setEditProfile(false)
            }
        }).catch((error) => {
            alert(error)
        });
    }

    const handleChangeBank = (event) => {
        const value = event.target.value;
        const type = event.target.getAttribute('inputtype')
        var newBank = {...bank};
        newBank[type] = value;
        setBank(newBank)
    }

    const saveBank = () => {
        const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        var newBank = {...bank};
        newBank["update_category"] = "bank";
        API.post(
            `api/dealer_profile/${user_id}/`,
            newBank
        ).then((response) => {
            const {status} = response;
            if(status === 200) {
                setEditBank(false)
            }
        }).catch((error) => {
            alert(error)
        });
    }

    const saveTax = () => {
        const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        API.post(
            `api/dealer_profile/${user_id}/`,
            {
                "update_category": "tax",
                "gst": profile.dealerdetails__gst
            }
        ).then((response) => {
            const {status} = response;
            if(status === 200) {
                setEditTax(false)
            }
        }).catch((error) => {
            alert(error)
        });
    }

    return(
        <><Nav />
            <Grid container className='flex-column' p={3} mb={5} md={9} sx={{margin:"auto"}}>
                <Grid container className='flex-space'>
                    <Grid item className='flex'>
                        <PersonIcon />
                        <Typography color={"#396773"}>Profile Details</Typography>
                    </Grid>
                    <Grid item>
                        <EditNoteIcon onClick={() => setEditProfile(true)} />
                    </Grid>
                </Grid>
                <Grid container p={2} my={2} style={{background: "#FFFCF9"}}>
                    <Grid item xs={12} my={1}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography color={"#5A8E9B"}>Your Name</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {editProfile ?
                                    <TextField size="small" variant='outlined' value={profile.name} inputProps={{ 'inputtype': 'name' }} onChange={handleChangeProfile} />
                                    :
                                    <Typography color={"#555555"}>{profile.name}</Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography color={"#5A8E9B"}>Phone</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography color={"#555555"}>{profile.phone}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} my={1}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography color={"#5A8E9B"}>Email</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {editProfile ?
                                    <TextField size="small" variant='outlined' value={profile.email} inputProps={{ 'inputtype': 'email' }} onChange={handleChangeProfile} />
                                    :
                                    <Typography color={"#555555"}>{profile.email}</Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} mb={3}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography color={"#5A8E9B"}>Address</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {editProfile ?
                                    <TextField size="small" variant='outlined' value={profile.address} inputProps={{ 'inputtype': 'address' }} onChange={handleChangeProfile} />
                                    :
                                    <Typography color={"#555555"}>{profile.address}</Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        editProfile && <Grid container className='flex-center'>
                                <Button variant='contained' onClick={saveProfile}>Save</Button>
                            </Grid>
                    }
                </Grid>
                <Grid container className='flex-space'>
                    <Grid item className='flex'>
                        <AccountBalanceIcon />
                        <Typography color={"#396773"}>Bank Details</Typography>
                    </Grid>
                    <Grid item>
                        <EditNoteIcon onClick={() => setEditBank(true)} />
                    </Grid>
                </Grid>
                <Grid container p={2} my={2} style={{background: "#FFFCF9"}}>
                    <Grid item xs={12} my={1}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography color={"#5A8E9B"}>Bank Name</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {editBank ?
                                    <TextField size="small" variant='outlined' value={bank.account_name} inputProps={{ 'inputtype': 'account_name' }} onChange={handleChangeBank} />
                                    :
                                    <Typography color={"#555555"}>{bank.account_name}</Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} my={1}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography color={"#5A8E9B"}>IFSC</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {editBank ?
                                    <TextField size="small" variant='outlined' value={bank.ifsc} inputProps={{ 'inputtype': 'ifsc' }} onChange={handleChangeBank} />
                                    :
                                    <Typography color={"#555555"}>{bank.ifsc}</Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} mb={3}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography color={"#5A8E9B"}>Account No.</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {editBank ?
                                    <TextField size="small" variant='outlined' value={bank.account_no} inputProps={{ 'inputtype': 'account_no' }} onChange={handleChangeBank} />
                                    :
                                    <Typography color={"#555555"}>{bank.account_no}</Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        editBank && <Grid container className='flex-center'>
                                <Button variant='contained' onClick={saveBank}>Save</Button>
                            </Grid>
                    }
                </Grid>
                <Grid container className='flex-space'>
                    <Grid item className='flex'>
                        <RequestPageIcon />
                        <Typography color={"#396773"}>Tax Details</Typography>
                    </Grid>
                    <Grid item>
                        <EditNoteIcon onClick={() => setEditTax(true)} />
                    </Grid>
                </Grid>
                <Grid container p={2} my={2} style={{background: "#FFFCF9"}}>
                    <Grid item xs={12} my={1}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography color={"#5A8E9B"}>GSTIN Number</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {editTax ?
                                    <TextField size="small" variant='outlined' value={profile.dealerdetails__gst} inputProps={{ 'inputtype': 'dealerdetails__gst' }} onChange={handleChangeProfile} />
                                    :
                                    <Typography color={"#555555"}>{profile.dealerdetails__gst}</Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        editTax && <Grid container className='flex-center'>
                                <Button variant='contained' onClick={saveTax}>Save</Button>
                            </Grid>
                    }
                </Grid>
            </Grid>
        </>
    )
}