import { Grid } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router';
import { Nav } from '../components/Header/Nav';

export const ViewBill = (props) => {
    const location = useLocation();
    const bill = location.state.bill;

    return(
        <><Nav />
            <Grid container className="flex-center" p={3}>
                <img src={bill} alt="bill" style={{width: '100%'}} />
            </Grid>
        </>
    )
}