import React from 'react';
import { BrowserRouter as Router, useHistory} from 'react-router-dom';
import { router as Route} from './pages/router';

const App = () => {
  const history = useHistory()
  
  return (
    <Router>
      <Route history={history} />
    </Router>
  );
}

export default App;
