import React from 'react'
import { Card, Grid, IconButton, Typography } from '@mui/material'

export const StatsCard = (props) => {    
    return (
        <Card style={{padding: '20px', marginBottom: '30px', borderRadius: '8px', ...props.style}}>
            <Grid container className='flex-space'>
                <Grid item container className='flex-start' xs={8}>
                    <IconButton color='inherit' size="large">
                        {props.icon}
                    </IconButton>
                    <Typography variant='body1'>
                        {props.text}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='h3' className='text-end'>
                        {props.value}
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    )
}
