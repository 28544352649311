import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import logo from '../assets/img/aapka-logo.png';
import MuiPhoneNumber from 'material-ui-phone-number';
import OtpInput from 'react-otp-input';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import '../App.css'
export const Login = (props) => {
    const [mobile, setMobile] = useState('')
    const [loadOtp, setLoadOTP] = useState(false)
    const [otp, setOtp] = useState('')
    const [userId, setUserId] = useState(0)
    const history = useHistory()

    useEffect(() => {
        const user_id = localStorage.getItem("userId");
        if (user_id) {
            console.log('user present')
            history.push('/home');
        }
    },[history])

    const handleChange = (newValue) => {
        setMobile(newValue.replace(/\D/g, ''))
    }

    const sendOTP = async() => {
        setLoadOTP(true)
        const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        await API.post(
            "api/user/logins/",
            { 
              mobile: mobile.substring(2),
              group_id: 16 //customer group id
            }
        ).then((response) => {
            const {status, data} = response;
            if(status === 200) {
                setUserId(data.id)
            }
        }).catch((error) => {
            alert("User does not exist, Please check with aapkapainter team")
            setLoadOTP(false)
        });
    }

    const verifyOTP = async() => {
        const API = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
        await API.post(
            "api/user/otpverify/",
            { 
              id: userId,
              otp: otp
            }
        ).then((response) => {
            const {status} = response;
            if(status === 200) {
                localStorage.setItem('userId', userId)
                localStorage.setItem('phone',mobile)
                props.history.push('/home')
            }
        }).catch((error) => {
            alert("OTP seems invalid, Please try again")
        });
        setLoadOTP(false)
    }

    return (
        <Grid container className='flex-center text-center'>
            <Grid item xs={12}>
                <img src={logo} alt="logo" />
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h3'>
                    Login!
                </Typography>
            </Grid>
            {!loadOtp ? 
                <><Grid item my={4}>
                    <MuiPhoneNumber defaultCountry={'in'} value={mobile} onChange={handleChange}
                        regions={'asia'}
                        style={{ width: "100%" }}
                        variant="outlined"
                        margin="normal" />
                   </Grid><Grid item xs={12}>
                        <Button variant="contained" disabled={mobile.length < 12} onClick={sendOTP}>Send OTP</Button>
                    </Grid></>
            :
                <><Grid item my={4}>
                    <OtpInput value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        inputStyle="inputStyle"
                        renderSeparator={<span> </span>}
                        renderInput={(props) => <input {...props} />} />
                </Grid><Grid item xs={12}>
                        <Button variant="contained" onClick={verifyOTP}>Verify OTP</Button>
                    </Grid></>
            }
        </Grid>
    );
}